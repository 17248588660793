<template>
  <v-container fluid>
    <V-row>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          collection="sessions"
          :items="sessions"
          :itemHeader="sessionItemHeader"
        />
      </v-col>
    </V-row>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      sessions: [],
      sessionItemHeader: [
        { value: "countery", text: "البلد" },
        { value: "description", text: "الوصف" },
        { value: "price", text: "السعر" },
        { value: "phone", text: "الهاتف" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {},
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.fetchData();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData() {
      try {
        const data = await ApiService.get(
          "items/sessions?fields=id,encountery,endescription,countery,description,price,currency_id.title,currency_id.id,phone,image"
        );
        this.sessions = data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchData(undefined);
  },
};
</script>

<style></style>
